import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Message, MessageType } from '../types';
import MessageComponent from './Message';
import { useAccount, useConfig, useSwitchChain, useConnect } from 'wagmi';
import { sendTransaction } from '@wagmi/core';
import { API_BASE_URL, WS_CONNECTION_TYPE } from '../config';
import QRCode from 'qrcode';
import {LightTheme} from '../styles/colors';
import { IoMdArrowUp, IoMdImage } from 'react-icons/io';
import TextareaAutosize from 'react-textarea-autosize';

interface ChatProps {
  // sessionId and setSessionId removed from props
}

interface StreamContent {
  type: 'text';
  text: string;
}

interface StreamPayload {
  content: StreamContent[];
  stop_reason: string | null;
}

const Chat: React.FC<ChatProps> = () => {
  const { address, chainId, isConnected } = useAccount();
  const { connect, connectors } = useConnect();
  const config = useConfig();
  const { switchChain } = useSwitchChain();

  const [messages, setMessages] = useState<Message[]>([]);
  const [sampleMessages] = useState([
    "Create an X post from my wallet",
    "What can you do for me?",
  ]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const messageCounterRef = useRef(0);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const shouldScrollRef = useRef(true);
  const isStreamingRef = useRef(false);
  const chainIdRef = useRef(chainId);

  const hideImageOptions = true;

  const generateUniqueId = () => {
    messageCounterRef.current += 1;
    return `${Date.now()}-${messageCounterRef.current}`;
  };

  const updateUserState = useCallback(() => {
    if (websocket && websocket.readyState === WebSocket.OPEN && sessionId && address) {
      websocket.send(JSON.stringify({
        type: 'UPDATE_USER_STATE',
        payload: { 
          evmWalletAddress: address, 
          solanaWalletAddress: '',
          sessionId,
        },
        platform: 'web',
        sessionId: sessionId
      }));
    }
  }, [websocket, sessionId, address]);

  const handleSignAndSendTransactions = useCallback(async (txObjectAndSimulations: any[], ws: WebSocket | null) => {
    if (!address) {
      throw new Error('Wallet not connected');
    }

    console.debug(txObjectAndSimulations)

    const transactionHashes: string[] = [];

    for (const txObjectAndSimulation of txObjectAndSimulations) {
      const txObject = txObjectAndSimulation.txObject
      try {
        // Use chainIdRef.current instead of chainId
        if (chainIdRef.current !== txObject.chainId) {
          try {
            await switchChain({ chainId: txObject.chainId });
          } catch (switchError) {
            console.error('Failed to switch network:', switchError);
            throw new Error('Failed to switch to required network');
          }
        }

        const transactionHash = await sendTransaction(config, {
          account: address,
          to: txObject.to,
          value: BigInt(txObject.value),
          data: txObject.data,
          chainId: txObject.chainId,
          gas: txObject.gasLimit ? BigInt(txObject.gasLimit) : undefined,
          gasPrice: txObject.gasPrice ? BigInt(txObject.gasPrice) : undefined,
          maxFeePerGas: txObject.maxFeePerGas ? BigInt(txObject.maxFeePerGas) : undefined,
          maxPriorityFeePerGas: txObject.maxPriorityFeePerGas ? BigInt(txObject.maxPriorityFeePerGas) : undefined,
        });

        console.log('Transaction sent:', transactionHash);
        transactionHashes.push(transactionHash);

    

      // Send all transaction hashes back to the backend
      ws?.send(JSON.stringify({
        type: 'SIGNED_AND_SENT_TRANSACTIONS',
        payload: {
          signedAndSentTxs: transactionHashes,
          sessionId: sessionId
        }
      }));

        // Update user state after successful transaction
        updateUserState();

      } catch (error) {
        console.error('Error in transaction process:', error);
        ws?.send(JSON.stringify({
          type: 'TRANSACTION_ERROR',
          payload: {
            error: (error as Error).message || 'Unknown error',
            sessionId: sessionId
          }
        }))
      }
    }
  }, [address, config, switchChain, sessionId, updateUserState]); // Remove chainId from dependencies
  
  const handleWebSocketMessage = useCallback((event: MessageEvent, ws: WebSocket | null) => {
    if (!isConnected) return;
    const data = JSON.parse(event.data);
    
    switch (data.type) {
      case 'SESSION_ID':
        console.log('Received session ID:', data.payload);
        setSessionId(data.payload);
        break;
      case 'END':
        console.log('Received END message');
        setIsLoading(false);
        break;
      case 'SIGN_AND_SEND_REQUEST':
        console.log('Received sign and send request:', data.payload);
        handleSignAndSendTransactions(data.payload, ws);
        break;
      case 'BOT_MESSAGE':
        console.log('Adding new bot message:', data.payload);
        setMessages(prevMessages => [
          ...prevMessages,
          { 
            id: generateUniqueId(), 
            text: data.payload, 
            sender: 'bot', 
            timestamp: new Date(),
            type: MessageType.TEXT 
          }
        ]);
        break;
      case 'BOT_MESSAGE_STREAM':
        const { content, stop_reason } = data.payload as StreamPayload;
        isStreamingRef.current = true;
        setMessages(prevMessages => {
          let updatedMessages = [...prevMessages];

          if (content.length === 0) {
            // Add a new empty bot message
            updatedMessages.push({
              id: generateUniqueId(),
              text: '',
              sender: 'bot',
              timestamp: new Date(),
              type: MessageType.TEXT
            });
          } else {
            // Update the last bot message with new content
            const lastBotMessageIndex = updatedMessages.findLastIndex(msg => msg.sender === 'bot');
            const newContent = content
              .filter((item: StreamContent) => item.type === 'text')
              .map((item: StreamContent) => item.text)
              .join('');

            if (lastBotMessageIndex !== -1) {
              updatedMessages[lastBotMessageIndex] = {
                ...updatedMessages[lastBotMessageIndex],
                text: newContent,
                type: MessageType.TEXT
              };
            } else {
              // If no bot message exists, create a new one
              updatedMessages.push({
                id: generateUniqueId(),
                text: newContent,
                sender: 'bot',
                timestamp: new Date(),
                type: MessageType.TEXT
              });
            }
          }

          return updatedMessages;
        });
        
        // Update streaming state when message is complete
        if (stop_reason === 'end_turn') {
          isStreamingRef.current = false;
          setIsLoading(false);
        }
        break;
      case 'TOOL_USE':
        console.log('Tool being used:', data.payload);
        setMessages(prevMessages => [
          ...prevMessages,
          { 
            id: generateUniqueId(), 
            text: data.payload, 
            sender: 'bot', 
            timestamp: new Date(),
            type: MessageType.TOOL_USE 
          }
        ]);
        break;
      case 'ERROR':
        console.error('Received error:', data.payload);
        setMessages(prevMessages => [
          ...prevMessages,
          { 
            id: generateUniqueId(), 
            text: `Error: ${data.payload}`, 
            sender: 'bot', 
            timestamp: new Date(),
            type: MessageType.ERROR
          }
        ]);
        setIsLoading(false);
        break;
      case 'DISPLAY_QR_CODE':
        console.log('Received QR code request:', data.payload);
        handleQRCodeRequest(data.payload);
        break;
      default:
        // console.warn('Unknown message type:', data.type);
    }
  }, [handleSignAndSendTransactions, setSessionId, isConnected]);

  const handleQRCodeRequest = async (payload: { qrCode: string}) => {
    try {
      const { qrCode } = payload;
      const qrCodeDataUrl = await QRCode.toDataURL(qrCode, {
        width: 1024,
        margin: 2,
        errorCorrectionLevel: 'H'
      });
      setMessages(prevMessages => [
        ...prevMessages,
        { 
          id: generateUniqueId(), 
          text: qrCodeDataUrl,
          sender: 'bot', 
          timestamp: new Date(),
          type: MessageType.QR_CODE
        }
      ]);
    } catch (error) {
      console.error('Failed to generate QR code:', error);
      setMessages(prevMessages => [
        ...prevMessages,
        { 
          id: generateUniqueId(), 
          text: 'Failed to generate QR code', 
          sender: 'bot', 
          timestamp: new Date(),
          type: MessageType.ERROR
        }
      ]);
    }
  };

  const handleSampleMessageClick = (message: string) => {
    if (!isLoading && websocket && websocket.readyState === WebSocket.OPEN) {
      const userMessage: Message = {
        id: generateUniqueId(),
        text: message,
        sender: 'user',
        timestamp: new Date(),
        type: MessageType.TEXT
      };
      setMessages(prevMessages => [...prevMessages, userMessage]);
      setIsLoading(true);

      websocket.send(JSON.stringify({
        type: 'USER_MESSAGE',
        payload: {
          text: message,
        },
        platform: 'web',
        sessionId: sessionId
      }));
    }
  };

  useEffect(() => {
    let ws: WebSocket | null = null;

    const connectWebSocket = () => {
      if (websocket && (websocket.readyState === WebSocket.OPEN || websocket.readyState === WebSocket.CONNECTING)) {
        return;
      }

      ws = new WebSocket(`${WS_CONNECTION_TYPE}://${API_BASE_URL.replace(/^https?:\/\//, '')}/api/chat/ws`);

      ws.onopen = () => {
        console.log('WebSocket connected');
        setWebsocket(ws);
      };

      ws.onmessage = (event) => handleWebSocketMessage(event, ws);

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        setSessionId(undefined);
      };

      ws.onclose = (event) => {
        console.log('WebSocket closed:', event.reason);
        setWebsocket(null);
        setSessionId(undefined);
        setTimeout(connectWebSocket, 5000);
      };
    };

    if (!websocket) {
      connectWebSocket();
    }

    return () => {
      // if (ws) {
      //   ws.close();
      // }
    };
  }, [websocket, handleWebSocketMessage]);

  const handleImageSelect = (file: File) => {
    if (hideImageOptions) return;
    
    if (file && file.type.startsWith('image/')) {
      setSelectedImage(file);
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    }
  };

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (hideImageOptions) return;
    
    const file = event.target.files?.[0];
    if (file) {
      handleImageSelect(file);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (hideImageOptions) return;
    
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];
    if (file && file.type.startsWith('image/')) {
      handleImageSelect(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    // Remove visual feedback
    event.currentTarget.style.backgroundColor = '';
  };

  const clearSelectedImage = () => {
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }
    setSelectedImage(null);
    setPreviewUrl(null);
  };

  const getInputPlaceholder = () => {
    if (!isConnected) {
      return "Please connect your wallet to continue...";
    }
    if (!sessionId) {
      return "Connecting with your assistant...";
    }
    if (isLoading) {
      return "Waiting for response...";
    }
    return "Type your message...";
  };

  const handleSend = async () => {
    if (!isConnected) {
      if (connectors.length > 0) {
        await connect({ connector: connectors[0] });
      } else {
        setMessages(prev => [...prev, {
          id: generateUniqueId(),
          text: "No wallet connectors available. Please install a Web3 wallet like MetaMask.",
          sender: 'bot',
          timestamp: new Date(),
          type: MessageType.ERROR
        }]);
      }
      return;
    }

    if (!sessionId) {
      return;
    }

    if ((!input.trim() && !selectedImage) || isLoading || !websocket || websocket.readyState !== WebSocket.OPEN) {
      return;
    }

    let imageData: string | null = null;
    if (selectedImage) {
      // Convert image to base64
      const reader = new FileReader();
      imageData = await new Promise((resolve) => {
        reader.onloadend = () => {
          // Strip the data URL prefix before sending to backend
          const base64Data = (reader.result as string).split(',')[1];
          resolve(base64Data);
        };
        reader.readAsDataURL(selectedImage);
      });
    }

    const newMessages: Message[] = [];

    // Add text message if present
    if (input.trim()) {
      newMessages.push({
        id: generateUniqueId(),
        text: input.trim(),
        sender: 'user',
        timestamp: new Date(),
        type: MessageType.TEXT
      });
    }

    // Add image message if present
    if (imageData) {
      newMessages.push({
        id: generateUniqueId(),
        // For display, we need to add the data URL prefix back
        text: `data:${selectedImage!.type};base64,${imageData}`,
        sender: 'user',
        timestamp: new Date(),
        type: MessageType.IMAGE
      });
    }

    // Add all new messages to state
    setMessages(prevMessages => [...prevMessages, ...newMessages]);
    setInput('');
    setIsLoading(true);

    // Send to backend with platform information
    websocket.send(JSON.stringify({
      type: 'USER_MESSAGE',
      payload: {
        text: input.trim(),
        imageData: imageData,
      },
      platform: 'web',
      sessionId: sessionId
    }));

    if (selectedImage) {
      clearSelectedImage();
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && !isLoading) {
      e.preventDefault(); // Prevent new line
      handleSend();
    }
  };

  useEffect(() => {
    if ((shouldScrollRef.current || isStreamingRef.current) && messagesContainerRef.current) {
      const scrollToBottom = () => {
        const container = messagesContainerRef.current!;
        const { scrollHeight, clientHeight } = container;
        container.scrollTop = scrollHeight - clientHeight;
      };
      
      // Initial scroll
      requestAnimationFrame(scrollToBottom);
      
      // Secondary scroll after a short delay to catch any late updates
      setTimeout(() => {
        requestAnimationFrame(scrollToBottom);
      }, 100);
    }
  }, [messages]);

  const handleScroll = () => {
    if (messagesContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
      const atBottom = scrollHeight - scrollTop - clientHeight < 1;
      shouldScrollRef.current = atBottom;
    }
  };

  // Add a new useEffect to call updateUserState when address changes or websocket state changes
  useEffect(() => {
    updateUserState();
  }, [address, websocket, updateUserState]);

  // Add this useEffect to check wallet connection on load
  useEffect(() => {
    if (!isConnected && connectors.length > 0) {
      // Try to connect with the first available connector
      connect({ connector: connectors[0] })
    }
  }, [isConnected, connectors, connect]);

  // Add this useEffect to keep the ref updated
  useEffect(() => {
    chainIdRef.current = chainId;
  }, [chainId]);

  const handleOptionClick = (option: string) => {
    if (!isLoading && websocket && websocket.readyState === WebSocket.OPEN) {
      const userMessage: Message = {
        id: generateUniqueId(),
        text: option,
        sender: 'user',
        timestamp: new Date(),
        type: MessageType.TEXT
      };
      setMessages(prevMessages => [...prevMessages, userMessage]);
      setIsLoading(true);

      websocket.send(JSON.stringify({
        type: 'USER_MESSAGE',
        payload: {
          text: option,
        },
        platform: 'web',
        sessionId: sessionId
      }));
    }
  };

  const handleReset = useCallback(() => {
    // Reset messages
    setMessages([]);
    
    // Close existing WebSocket connection
    if (websocket) {
      websocket.close();
    }

    // Reset session ID
    setSessionId(undefined);

    // Reset loading state
    setIsLoading(false);

    // Clear any selected image
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }
    setSelectedImage(null);
    setPreviewUrl(null);

    // The WebSocket will automatically reconnect due to the useEffect
  }, [websocket, previewUrl]);

  return (
    <div 
      style={styles.chatWrapper}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      <div style={styles.chatContainer}>
        <div 
          className="messages-container" 
          style={styles.messagesContainer} 
          ref={messagesContainerRef}
          onScroll={handleScroll}
        >
          {messages.length === 0 && (
            <div className="sample-messages" style={styles.sampleMessages}>
              {/* <p style={styles.sampleMessagesTitle}>Try:</p> */}
              {sampleMessages.map((message, index) => (
                <button 
                  key={index} 
                  onClick={() => handleSampleMessageClick(message)}
                  style={styles.sampleMessageButton}
                  className="sample-message-button"
                >
                  {message}
                </button>
              ))}
            </div>
          )}
          {messages.map((message, index) => (
            message.type === MessageType.TOOL_USE && index !== messages.length - 1 || !message.text
              ? null
              : <MessageComponent 
                  key={index} 
                  message={message}
                  onOptionClick={handleOptionClick}
                />
          ))}
        </div>
        <div className="input-container" style={styles.inputContainer}>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileInput}
            accept="image/*"
            style={{ display: 'none' }}
            disabled={!isConnected || !sessionId || isLoading}
          />
          
          <div style={{
            ...styles.inputWrapper,
            ...((!isConnected || !sessionId || isLoading) ? styles.inputWrapperDisabled : {})
          }}>
            <button
              onClick={handleReset}
              disabled={!isConnected || !sessionId || isLoading}
              style={{
                ...styles.leftButton,
                ...styles.resetButton,
                ...((!isConnected || !sessionId || isLoading) ? styles.buttonDisabled : {})
              }}
              aria-label="Reset chat"
            >
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
                <path d="M3 3v5h5" />
              </svg>
            </button>

            {!hideImageOptions && (
              <button
                onClick={() => fileInputRef.current?.click()}
                disabled={!isConnected || !sessionId || isLoading}
                style={{
                  ...styles.leftButton,
                  ...styles.imageButton,
                  ...((!isConnected || !sessionId || isLoading) ? styles.buttonDisabled : {})
                }}
                aria-label="Upload image"
              >
                <IoMdImage size={20} />
              </button>
            )}

            {previewUrl && (
              <div style={styles.imagePreview}>
                <img src={previewUrl} alt="Preview" style={styles.previewImage} />
                <button 
                  onClick={clearSelectedImage} 
                  style={styles.clearImageButton}
                  aria-label="Remove image"
                  disabled={!isConnected || !sessionId || isLoading}
                >
                  ×
                </button>
              </div>
            )}
            <TextareaAutosize
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder={getInputPlaceholder()}
              onKeyDown={handleKeyPress}
              disabled={!isConnected || !sessionId || isLoading}
              minRows={1}
              maxRows={8}
              style={{
                ...styles.inputField,
                ...((!isConnected || !sessionId || isLoading) ? styles.inputFieldDisabled : {})
              }}
            />
            <button
              onClick={handleSend}
              disabled={!isConnected || !sessionId || isLoading}
              style={{
                ...styles.sendButton,
                ...((!isConnected || !sessionId || isLoading) ? styles.sendButtonDisabled : {})
              }}
              aria-label="Send message"
            >
              <IoMdArrowUp size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  chatWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'relative' as const,
    transition: 'background-color 0.2s ease', // Smooth transition for drag feedback
  },
  chatContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    height: '80vh',
    width: '80%',
    borderRadius: '24px',
    border: '2px solid black',
    // boxShadow: '0 8px 24px rgba(0, 0, 0, 0.3)',
    overflow: 'hidden',
  },
  messagesContainer: {
    flex: 1,
    overflowY: 'auto' as const, // Enable vertical scrolling
    padding: '20px',
    display: 'flex',
    flexDirection: 'column' as const,
    scrollBehavior: 'smooth' as const,
    borderRadius: '12px',
  },
  sampleMessages: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    padding: '20px',
    boxSizing: 'border-box' as const,
  },
  sampleMessagesTitle: {
    color: LightTheme.textColor,
    fontSize: '14px',
    marginBottom: '20px',
    textAlign: 'center' as const,
  },
  sampleMessageButton: {
    padding: '10px 15px',
    margin: '5px',
    backgroundColor: LightTheme.primaryAI,
    color: 'white',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    fontSize: '14px',
    transition: 'background-color 0.3s, color 0.3s',
    width: '50%',
    textAlign: 'center' as const,
  },
  loadingMessage: {
    color: LightTheme.textColor,
    fontStyle: 'italic',
    // margin: '10px 0',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    padding: '20px',
    position: 'relative' as const,
    width: '60%',
    margin: '0 auto',
  },
  inputWrapper: {
    display: 'flex' as const,
    alignItems: 'stretch' as const,
    width: '100%',
    position: 'relative' as const,
    // border: `1px solid ${LightTheme.primaryAI}`,
    borderRadius: '20px',
    backgroundColor: LightTheme.primaryBG,
    transition: 'border-color 0.2s ease',
  },
  inputWrapperDisabled: {
    // border: `1px solid ${LightTheme.lightGray}`,
  },
  imagePreview: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    position: 'relative' as const,
    borderRight: `1px solid ${LightTheme.primaryAI}`,
  },
  previewImage: {
    maxWidth: '40px',
    maxHeight: '40px',
    borderRadius: '4px',
    objectFit: 'cover' as const,
  },
  clearImageButton: {
    position: 'absolute' as const,
    top: '-8px',
    right: '-8px',
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    backgroundColor: LightTheme.primaryAI,
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    fontSize: '12px',
  },
  inputField: {
    flex: 1,
    border: `none`,
    borderRadius: '20px',
    padding: '10px 15px',
    fontSize: '14px',
    backgroundColor: LightTheme.darkBG,
    color: LightTheme.textColor,
    outline: 'none',
    resize: 'none' as const,
    lineHeight: '1.5',
    fontFamily: 'inherit',
  },
  inputFieldDisabled: {
  },
  sendButton: {
    paddingRight: '15px',
    backgroundColor: LightTheme.primaryBG,
    color: LightTheme.primaryAI,
    border: 'none',
    borderLeft: 'none',
    borderRadius: '0 20px 20px 0',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  sendButtonDisabled: {
    cursor: 'not-allowed',
    color: LightTheme.lightGray,
  },
  imageButton: {
    // padding: '10px 15px',
    backgroundColor: LightTheme.primaryBG,
    color: LightTheme.primaryAI,
    border: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageButtonDisabled: {
    cursor: 'not-allowed',
    color: LightTheme.lightGray,
  },
  resetButton: {
    padding: '5px 5px',
    backgroundColor: LightTheme.primaryBG,
    color: LightTheme.primaryAI,
    border: 'none',
    borderLeft: 'none',
    borderRadius: '0 20px 20px 0',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '16px',
  },
  resetButtonDisabled: {
    cursor: 'not-allowed',
    color: LightTheme.lightGray,
  },
  leftButton: {
    padding: '5px 5px',
    backgroundColor: LightTheme.primaryBG,
    color: LightTheme.primaryAI,
    border: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonDisabled: {
    cursor: 'not-allowed',
    color: LightTheme.lightGray,
  },
};

export default Chat;